@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next'),
    url(./assets/styles/AvenirNextPro/AvenirNextLTPro-Medium.otf)
      format('opentype');
  font-family: 'Avenir Next';
  font-display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  letter-spacing: -0.3px;
  font-style: 'Medium S';
}
@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next'),
    url(./assets/styles/AvenirNextPro/AvenirNextLTPro-Medium.otf)
      format('opentype');
  font-family: 'Avenir Next';
  font-display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  letter-spacing: -0.3px;
  font-style: 'Medium XS';
}
@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next'),
    url(./assets/styles/AvenirNextPro/AvenirNextLTPro-Medium.otf)
      format('opentype');
  font-display: block;
  font-family: 'Avenir Next';
  font-display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  /* identical to box height, or 153% */

  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  font-style: 'Medium L';
}

@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next'),
    url(./assets/styles/AvenirNextPro/AvenirNextLTPro-Bold.otf)
      format('opentype');
  font-family: 'Avenir Next';
  font-display: block;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;

  letter-spacing: -0.3px;
  font-style: 'Bold';
}
@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next'),
    url(./assets/styles/AvenirNextPro/AvenirNextLTPro-Bold.otf)
      format('opentype');
  font-family: 'Avenir Next';
  font-display: block;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;

  letter-spacing: -0.3px;
  font-style: 'Bold XL';
}
@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next'),
    url(./assets/styles/AvenirNextPro/AvenirNextLTPro-Bold.otf)
      format('opentype');
  font-family: 'Avenir Next';
  font-display: block;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;

  letter-spacing: -0.3px;
  font-style: 'Bold XXL';
}

@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next'),
    url(./assets/styles/AvenirNextPro/AvenirNextLTPro-Demi.otf)
      format('opentype');
  font-family: 'Avenir Next';
  font-display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  letter-spacing: -0.257143px;
  font-style: 'Semibold XS';
}
@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next'),
    url(./assets/styles/AvenirNextPro/AvenirNextLTPro-Demi.otf)
      format('opentype');
  font-family: 'Avenir Next';
  font-display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;

  letter-spacing: -0.3px;
  font-style: 'Semibold S';
}
@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next'),
    url(./assets/styles/AvenirNextPro/AvenirNextLTPro-Demi.otf)
      format('opentype');
  font-family: 'Avenir Next';
  font-display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;

  letter-spacing: -0.3px;
  font-style: 'Semibold L';
}
@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next'),
    url(./assets/styles/AvenirNextPro/AvenirNextLTPro-Demi.otf)
      format('opentype');
  font-family: 'Avenir Next';
  font-display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;

  letter-spacing: -0.3px;
  font-style: 'Semibold XL';
}

@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next'),
    url(./assets/styles/AvenirNextPro/AvenirNextLTPro-Regular.otf)
      format('opentype');
  font-family: 'Avenir Next';
  font-display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  letter-spacing: -0.3px;
  font-style: 'Regular S';
}

@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next'),
    url(./assets/styles/AvenirNextPro/AvenirNextLTPro-Regular.otf)
      format('opentype');
  font-family: 'Avenir Next';
  font-display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  letter-spacing: -0.3px;
  font-style: 'Regular';
}
@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next'),
    url(./assets/styles/AvenirNextPro/AvenirNextLTPro-Regular.otf)
      format('opentype');
  font-display: block;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;

  letter-spacing: 0.2px;
  font-style: 'Regular L';
}

::-webkit-scrollbar {
  display: none;
}

html {
  overflow-x: hidden !important;
  position: relative;
  overscroll-behavior: contain !important;
  overscroll-behavior-y: contain !important;
  body {
    overscroll-behavior: contain !important;
    overscroll-behavior-y: contain !important;

    min-height: 100vh;
    min-height: -webkit-fill-available;
    margin: 0px !important;
    overflow-x: hidden !important;
    width: 100% !important;
  }
  height: -webkit-fill-available;
}
.CardBrandIcon-container {
  right: 0px !important;
}

* {
  font-family: 'Avenir Next' !important;
}
.MuiTypography-root {
  font-family: 'Avenir Next' !important;
}
label {
  font-family: 'Avenir Next' !important;
  font-weight: 500;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20.8px;
}
input::-webkit-calendar-picker-indicator {
  display: none;
}

.MuiStepLabel-iconContainer {
  .Mui-active {
    text {
      fill: #383a49;
    }
  }
}
.MuiStepLabel-iconContainer {
  text {
    fill: rgba(0, 0, 0, 0.38);
  }
}
.desktop-stepper {
  .MuiStepLabel-label.Mui-disabled {
    color: var(--zen-sub-text, #9ca0ab);
    text-align: center;
    font-family: Avenir Next;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .MuiStepLabel-label.Mui-completed {
    color: var(--zen-sub-text, #9ca0ab);
    text-align: center;
    font-family: Avenir Next;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .MuiStepLabel-label.Mui-active {
    font-family: Avenir Next;
    color: var(--zen-text, #383a49);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .MuiStepConnector-root {
    width: 112px;
    left: calc(-50% + 13px);
  }
  .MuiStepConnector-root.Mui-completed {
    width: 112px;
    left: calc(-50% + 13px);

    span {
      border-color: #0179ff;
      border-top-style: solid;
      border-top-width: 1.5px !important;
    }
  }
  .MuiStepConnector-root.Mui-active {
    width: 112px;
    left: calc(-50% + 13px);

    span {
      border-color: #0179ff;
      border-top-style: solid;
      border-top-width: 1.5px !important;
    }
  }
  .MuiStepper-root {
    margin-inline: 40px;
  }
  .MuiStepLabel-root {
    flex-direction: column !important;
    display: flex important;

    justify-content: revert !important;
    margin-top: -8px;
  }
  .MuiStepLabel-iconContainer {
    order: 2 !important;
    margin-top: 8px;
  }
  .MuiStepLabel-labelContainer {
    order: 1 !important;
    position: relative;

    span {
      position: absolute;
      white-space: nowrap;
      width: 100%;
      height: 100%;
      text-align: center;
      margin-top: 0;
      bottom: 18px;
    }
  }
}
